import React from "react";

export default function FaqStatic({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Preguntas Frecuentes</h2>
                  <p className="lead">
                  ¡Bienvenido a la sección de Preguntas Frecuentes de ePlanner! Aquí encontrarás respuestas a las consultas más comunes sobre nuestra aplicación para organizar eventos sociales. Hemos recopilado información detallada para brindarte la mejor experiencia posible.

Si, después de revisar nuestras preguntas frecuentes, aún tienes alguna duda o consulta adicional, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte. Envía un correo electrónico a <a href="mailto: contacto@eplanner.app" className="color-primary">contacto@eplanner.app</a> y nuestro equipo de soporte estará encantado de proporcionarte la asistencia que necesitas. ¡Gracias por elegir E-Planner para tus eventos!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Puedo agregar más invitados después de confirmar mi evento?</h5>
                <p>
                  
Sí, puedes añadir más invitados en cualquier momento antes del evento. Simplemente accede a la aplicación, encuentra tu evento, ve a la sección de detalles y selecciona la opción para agregar invitados adicionales. Espera la confirmación de tu pago y !Listo!
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Es segura la información de mi tarjeta de crédito almacenada en la aplicación?</h5>
                <p>
                Sí, implementamos medidas de seguridad robustas para proteger la información de pago. Utilizamos tecnologías seguras para garantizar transacciones seguras y proteger tus datos.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Qué sucede si necesito cancelar mi evento? ¿Puedo obtener un reembolso?</h5>
                <p>
                Lamentablemente, no ofrecemos reembolsos una vez que el evento ha sido confirmado. Sin embargo, puedes comunicarte con nuestro equipo de soporte para discutir opciones para reagendar.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Cómo puedo realizar un seguimiento de los pagos y transacciones de mis eventos?</h5>
                <p>
                En la sección de "Historial de Pagos" de la aplicación, encontrarás un desglose detallado de todas las transacciones relacionadas con tus eventos. Ahí podrás realizar un seguimiento de los pagos. También podrás ver los recibos con tu proveedor bancario
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Cuál es el proceso si necesito cambiar la fecha de mi evento?</h5>
                <p>
                Lamentablemente, una vez que se ha confirmado la compra del evento, no es posible cambiar la fecha. Si necesitas organizar el evento en una fecha diferente, deberás realizar una nueva compra para la fecha deseada. Comunícate con nuestro equipo de soporte para obtener asistencia y discutir las opciones para reagendar tu evento.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Cómo puedo recibir confirmaciones de asistencia en tiempo real?</h5>
                <p>
                Las confirmaciones de asistencia se actualizan automáticamente en cuanto tus invitados confirman su asistencia. Dirígete a la sección del evento y verás la lista actualizada de invitados con sus estados de confirmación en tiempo real.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Puedo utilizar la aplicación para organizar más de un evento a la vez?</h5>
                <p>
                Sí, puedes organizar múltiples eventos simultáneamente. Simplemente navega a la sección de eventos y añade tantos como necesites. La aplicación te permite gestionarlos de manera independiente y eficiente.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>¿Cómo garantiza la aplicación la privacidad de mis datos y los de mis invitados?</h5>
                <p>
                La seguridad y privacidad son nuestras principales prioridades. Utilizamos medidas de seguridad avanzadas para proteger la información personal. Puedes obtener más detalles sobre nuestras prácticas de privacidad en la sección correspondiente de la aplicación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
