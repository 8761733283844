import React from "react";
import Footer from "../components/layout/footer/Footer";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";

export default function BlogWithSidebar() {
  return (
    <Layout>
      {/* <Navbar darkBg /> */}
      <PageHeader
        HeaderTitle="Términos, Condiciones y Políticas de Privacidad"
        PageTite="Términos, Condiciones y Políticas de Privacidad"
      />
      <div className="container my-3">
        <p><strong><span style={{fontSize: "20px"}}>T&eacute;rminos y condiciones de EPlanner </span></strong></p>
        <p><strong>Aceptaci&oacute;n de T&eacute;rminos:</strong></p>
        <p>Al utilizar esta aplicaci&oacute;n, aceptas cumplir con los t&eacute;rminos y condiciones establecidos a continuaci&oacute;n.</p>
        <p><strong>Costos y Pago:</strong></p>
        <p>a. Se cobrar&aacute; una tarifa por evento basada en el n&uacute;mero de invitados. Cada invitado tiene un costo de $35 MXN. Tarifa sujeta a cambios sin previo aviso. Los pagos deben realizarse antes de la confirmaci&oacute;n del evento.</p>
        <p><strong>Cambios en el N&uacute;mero de Invitados:</strong></p>
        <p>a. En cualquier momento, el usuario tiene la opci&oacute;n de a&ntilde;adir m&aacute;s invitados, con un costo adicional por cada invitado adicional.</p>
        <p>b. Los pagos adicionales por invitados deben realizarse antes del evento.</p>
        <p><strong>Cancelaciones y Modificaciones de Fecha:</strong></p>
        <p>a. No se ofrecen reembolsos para eventos confirmados.</p>
        <p>b. La fecha del evento no puede ser modificada una vez que se ha completado la compra del evento. Cualquier cambio en la fecha requerir&aacute; la compra de un nuevo evento.<strong>&nbsp;</strong></p>
        <p><strong>Responsabilidad del Usuario:</strong></p>
        <p>a. El usuario es responsable de la exactitud de la informaci&oacute;n proporcionada al organizar el evento.</p>
        <p>b. Cualquier informaci&oacute;n incorrecta puede afectar el costo y la viabilidad del evento.</p>
        <p><strong>Uso Adecuado:</strong></p>
        <p>a. La aplicaci&oacute;n debe utilizarse exclusivamente para la organizaci&oacute;n de eventos sociales.</p>
        <p>b. Cualquier uso indebido puede resultar en la suspensi&oacute;n o cancelaci&oacute;n de la cuenta del usuario.</p>
        <p><strong>Propiedad Intelectual:</strong></p>
        <p>a. Todos los derechos de propiedad intelectual de la aplicaci&oacute;n son propiedad de [Nombre de la Empresa].</p>
        <p>b. No se permite la reproducci&oacute;n o distribuci&oacute;n no autorizada del contenido de la aplicaci&oacute;n.</p>
        <p><strong>Limitaci&oacute;n de Responsabilidad:</strong></p>
        <p>a. La aplicaci&oacute;n no es responsable de cambios imprevistos en la disponibilidad de servicios de terceros (lugares, proveedores, etc.).</p>
        <p>b. La responsabilidad total de la aplicaci&oacute;n se limita al monto pagado por el usuario por el evento en cuesti&oacute;n.</p>
        <p><br /></p>
        <p><strong><span style={{fontSize: "20px"}}>Pol&iacute;tica de Privacidad de EPlanner:</span></strong></p>
        <p>Fecha de vigencia: Diciembre del 2023</p>
        <p><strong>1. Informaci&oacute;n Recopilada:</strong></p>
        <p>a. Recopilamos informaci&oacute;n personal como nombre, direcci&oacute;n de correo electr&oacute;nico y detalles del evento al registrarse y utilizar la aplicaci&oacute;n.</p>
        <p>b. Los datos de pago, como tarjetas de cr&eacute;dito, son recopilados para procesar los pagos de los eventos.</p>
        <p><strong>2. Uso de la Informaci&oacute;n:</strong></p>
        <p>a. La informaci&oacute;n personal se utiliza para confirmar la identidad del usuario y procesar transacciones de eventos.</p>
        <p>b. La informaci&oacute;n del evento se utiliza para organizar y coordinar los detalles del evento.</p>
        <p><strong>3. Almacenamiento Seguro:</strong></p>
        <p>a. Implementamos medidas de seguridad para proteger la informaci&oacute;n del usuario y de pago contra accesos no autorizados.</p>
        <p><strong>4. Compartir Informaci&oacute;n:</strong></p>
        <p>a. No compartimos informaci&oacute;n personal con terceros, excepto cuando es necesario para procesar transacciones o cumplir con requisitos legales.</p>
        <p><strong>5. Cookies y Tecnolog&iacute;as Similares:</strong></p>
        <p>a. Utilizamos cookies y tecnolog&iacute;as similares para mejorar la experiencia del usuario y recopilar datos de uso an&oacute;nimos.</p>
        <p><strong>6. Publicidad Personalizada:</strong></p>
        <p>a. Podemos utilizar informaci&oacute;n no identificable para ofrecer publicidad personalizada dentro de la aplicaci&oacute;n.</p>
        <p><strong>7. Acceso y Control de la Informaci&oacute;n:</strong></p>
        <p>a. Los usuarios tienen derecho a acceder, corregir o eliminar su informaci&oacute;n personal.</p>
        <p>b. Se proporcionan opciones para controlar la recepci&oacute;n de comunicaciones y la configuraci&oacute;n de privacidad.</p>
        <p><strong>8. Cambios en la Pol&iacute;tica de Privacidad:</strong></p>
        <p>a. La pol&iacute;tica de privacidad puede actualizarse peri&oacute;dicamente. Los cambios significativos se comunicar&aacute;n a los usuarios.</p>
        <p><strong>9. Menores de Edad:</strong></p>
        <p>a. La aplicaci&oacute;n no est&aacute; dirigida a menores de 18 a&ntilde;os. No recopilamos intencionalmente informaci&oacute;n de menores sin el consentimiento de los padres.</p>
        <p><strong>10. Contacto:</strong></p>
        <p>a. Para consultas relacionadas con la privacidad, los usuarios pueden ponerse en contacto a trav&eacute;s de [correo electr&oacute;nico de contacto].</p>
        <p>Al utilizar esta aplicaci&oacute;n, el usuario acepta los t&eacute;rminos de esta pol&iacute;tica de privacidad.</p>
      </div>
      {/* <Footer /> */}
    </Layout>
  );
}
