import React from "react";
import Countdown from "react-countdown";

export default function ComingSoon() {
  return (
    <>
    <section
      className="hero-section ptb-100 background-img full-screen"
      style={{
      background:
            "url('https://i.pinimg.com/originals/40/cc/45/40cc45937495e5c2cb145961e03a5a02.gif')no-repeat center center / cover",
      }}
      >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-lg-7">
            <div className="hero-content-left text-white text-center">
              <h1 className="text-white">Llegaremos pronto</h1>
              <p className="lead">
                Organiza tus eventos sin esfuerzo con <img src="assets/img/logo.png" alt="logo"
                className="img-fluid"/> Invitaciones digitales elegantes, confirmaciones instantáneas, organización de invitados en grupos y mucho más.
              </p>

              <div id="clock" className="countdown-wrap my-5">
                  <Countdown date={new Date('2023-12-25')}
                    renderer={({ days, hours, minutes, seconds, _completed }) => {
                    return <div className="row">
                      <div className="col">
                        <h2 className="mb-1">{days}</h2>
                        <h5>Días</h5>
                      </div>
                      <div className="col">
                        <h2 className="mb-1">{hours}</h2>
                        <h5>Horas</h5>
                      </div>
                      <div className="col">
                        <h2 className="mb-1">{minutes}</h2>
                        <h5>Minutos</h5>
                      </div>
                      <div className="col">
                        <h2 className="mb-1">{seconds}</h2>
                        <h5>Segundos</h5>
                      </div>
                    </div>
                  }}/>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
